/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import Loading from 'components/shared/Loading'
import ParseContent from 'components/shared/ParseContent'

// Duuf
import {
  FormDuuf,
  FormDuufProps,
  FormDuufGroup,
  FormDuufSubmit,
  useFormDuuf,
} from '@ubo/form-duuf'

export { FormDuufGroup, FormDuufSubmit, useFormDuuf }

interface FormProps
  extends Omit<
    FormDuufProps,
    'graphqlData' | 'renderStatus' | 'renderLoading'
  > {
  className?: string
  children?: React.ReactNode
}

const Form: React.FC<FormProps> = ({ className = '', children, ...rest }) => {
  const { allGfForm } = useStaticQuery<GatsbyTypes.formDuufQueryQuery>(graphql`
    query formDuufQuery {
      allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            formFields {
              id
              label
              labelPlacement
              type
              isRequired
              visibility
              placeholder
              checkboxLabel
              choices
              description
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  return (
    <FormDuuf
      className={className}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      graphqlData={allGfForm}
      renderLoading={() => <Loading />}
      renderStatus={(status) =>
        status?.status === 'error' ? (
          <ParseContent
            content={
              'Er is iets fout gegaan. Controleer de gegevens en of de bestanden niet groter waren dan 10MB'
            }
          />
        ) : (
          <ParseContent content={status?.content || ''} />
        )
      }
    >
      {children}
    </FormDuuf>
  )
}

export default Form
